import React, { forwardRef } from 'react';

import { SelectOutlined } from '@ant-design/icons';
import { NewTooltip as Tooltip, Text, Box } from '@lp/ds-next';
import { ColumnProps } from 'antd/lib/table';
import { useParams } from 'react-router-dom';

import { useTrackingCodes } from '../api/trackingCode/getTrackingCodes';
import { useRevokeTrackingCode } from '../api/trackingCode/revokeTrackingCode';
import { ITrackingCode } from '../types/trackingCode';
import EyeLink from '@/components/EyeLink';
import ActiveIcon from '@/components/icons/ActiveIcon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import InActiveIcon from '@/components/icons/InActiveIcon';
import IdCell from '@/components/tables/IdCell';
import StyledTable from '@/components/tables/StyledTable';
import useAntdColumns from '@/hooks/useAntdColumns';

const TrackingCodesTable = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const { id } = useParams<{ id: string }>();
  const { data: trackingCodes } = useTrackingCodes({
    shipmentId: id ?? '',
  });

  const { mutate: revokeTrackingCode } = useRevokeTrackingCode({
    shipmentId: id ?? '',
  });

  const roles = [
    'Invalide',
    'Additional tracker',
    'Admin',
    'Sender',
    'Recipient',
  ];

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'trackingCodeId',
      'email',
      'phoneNumber',
      'accountId',
      'role',
      'source',
      'isRevoked',
      'createdAt',
      'updatedAt',
    ],
    columnsSpecialProps: {
      trackingCodeId: {
        width: '6rem',
      },
      accountId: {
        render: (text, record) =>
          record.accountId ? <IdCell id={record.accountId} /> : '-',
      },
      source: {
        render: (_, record: ITrackingCode) =>
          record.sourceAuthApp?.clientName || '-',
      },
      isRevoked: {
        render: (text, record) => {
          const currentDate = Date.now() / 1000;

          return record?.revokedAt < currentDate ? (
            <InActiveIcon color="red" />
          ) : (
            <ActiveIcon />
          );
        },
        align: 'center',
      },
      role: {
        render: (roleId: any) => roles[roleId],
      },
      createdAt: { sorter: false },
      updatedAt: { sorter: false },
    },
    eyeLinkProps: {
      rest: {
        render: (text: any, record: any) =>
          record.accountId ? (
            <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ],
                },
              }}
              title={<Text variant="bodyTextM">Go to LP Account</Text>}
            >
              <EyeLink
                to={`/lp-account/${record.accountId}`}
                data-testid="LPAccount-link"
              />
            </Tooltip>
          ) : (
            '-'
          ),
      },
    },
    addDefaultColumns: false,
    defaultSort: {
      sortBy: 'updatedAt',
      order: 'ASC',
    },
    deleteIconProps: {
      rest: {
        filters: [{ text: 'Active Only', value: false }],
        onFilter: (value, record) => {
          const currentDate = Date.now() / 1000;

          return value === record?.revokedAt < currentDate;
        },
        render: (text, record) => (
          <Tooltip
            data-testid="revoke-link"
            title={
              <Text variant="bodyTextM">Revoke Delivery Key credentials </Text>
            }
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
            >
              <DeleteIcon
                onClick={() => revokeTrackingCode({ trackingCode: record.id })}
              />
            </Box>
          </Tooltip>
        ),
      },
    },
    externalLinkProps: {
      customRender: (record) => (
        <Tooltip
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ],
            },
          }}
          title={
            <Text variant="bodyTextM">
              Go to anonymous shipment’s details page
            </Text>
          }
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            <SelectOutlined
              data-testid="anonymous-link"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_PUBLIC_URL_WEBAPP}shipments/${record.id}/anonymous-detail`
                );
              }}
            />
          </Box>
        </Tooltip>
      ),
    },
  });

  return (
    <StyledTable
      data-testid="shipment-tracking-codes"
      loading={false}
      dataSource={trackingCodes}
      size="small"
      rowKey="id"
      columns={columns}
      pagination={false}
      ref={ref}
    />
  );
});

TrackingCodesTable.displayName = 'TrackingCodesTable';

export default TrackingCodesTable;
